/*
订单明细
*/

<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <label>创建时间：</label>
        <a-range-picker v-model="insetData.createTime" @change="createChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>支付时间：</label>
        <a-range-picker v-model="insetData.payTime" @change="payChange" :disabledDate="disabledDate" :defaultValue="[moment(getCurrentMonthFirst, dateFormat), moment(getNowFormatDate, dateFormat)]" size="small" />
      </a-form-item>
      <a-form-item>
        <label>注册时间：</label>
        <a-range-picker v-model="insetData.registerTime" @change="registerChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>首次成交日期：</label>
        <a-range-picker v-model="insetData.successTime" @change="firstChange" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>退款时间：</label>
        <a-range-picker @change="refundTimeChange" v-model="insetData.refundTime" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>请款日期：</label>
        <a-range-picker @change="requestFundsDateChange" v-model="insetData.requestFundsDate" :disabledDate="disabledDate" size="small" />
      </a-form-item>
      <a-form-item>
        <label>费用类型：</label>
        <a-select placeholder="请选择费用类型" v-model="insetData.costId" allowClear style="width: 150px" @change="orderChange">
          <a-select-option :value="1">官费</a-select-option>
          <a-select-option :value="2">代理费</a-select-option>
          <a-select-option :value="3">第三方费用</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>请款单号：</label>
        <a-input placeholder="请输入请款单号" v-model="insetData.requestFundsCode" allowClear size="small" style="width: 150px;" @change="orderCodeChange" />
      </a-form-item>
      <a-form-item>
        <label>费用名称：</label>
        <a-input placeholder="请输入费用名称" v-model="insetData.costInfoName" allowClear size="small" style="width: 150px;" @change="payCodeChange" />
      </a-form-item>
      <a-form-item>
        <label>分公司：</label>
        <a-select placeholder="请选择区域/分公司" v-model="insetData.filialeType" allowClear style="width: 200px" @change="filialeChange" @focus="filialeFocus">
          <a-select-option v-for="item in companyArr" :key="item">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>小组：</label>
        <a-select placeholder="请选择小组" v-model="insetData.groupeType" allowClear style="width: 200px" @change="groupeChange">
          <a-select-option v-for="item in groupArr" :key="item.groupId">{{ item.groupName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>顾问：</label>
        <a-select placeholder="请选择顾问" v-model="insetData.counselorType" allowClear style="width: 200px" @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" icon="search" @click="inquireClick('1')">查询</a-button>
        <a-button type="primary" icon="search" style="marginLeft: 15px;" @click="exportClick('2')">导出查询结果</a-button>
        <a-button style="marginLeft: 15px;" @click="insetClick('3')">重置</a-button>
      </a-form-item>
    </a-form>
    <!-- 表格部分 -->
    <div>
      <a-spin tip="加载中..." :spinning="loadingData">
        <a-table :columns="columns" size="middle" :dataSource="tableData" :pagination="false" :scroll="{ x: 3000 }" :bordered="true" rowKey="uid" />
      </a-spin>
    </div>
    <!-- 分页部分 -->
    <div class="pageClass">
      <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
    </div>
  </div>
</template>
<script>
import { getCompany, getGroup, getCounselor, getCostPageList, exportCostBaseData } from '../../server/orderList'
import { mapGetters } from 'vuex'
import fileDownload from 'js-file-download' // 导出文件插件
import moment from 'moment'

export default {
  name: 'CostList',
  data() {
    return {
      insetData: { //   搜索框model默认数据
        createTime: undefined, // 创建时间
        payTime: undefined, // 支付时间
        costId: undefined, // 费用类型
        requestFundsCode: undefined, // 请款单号
        costInfoName: undefined, // 费用名称
        registerTime: undefined, // 注册时间
        successTime: undefined, // 首次成交日期
        filialeType: undefined, // 顾问区域分公司
        groupeType: undefined, // 顾问组别
        counselorType: undefined, // 顾问姓名
        refundTime: undefined, // 退款时间
        requestFundsDate: undefined // 请款时间
      },
      dateFormat: 'YYYY-MM-DD', // 日期显示格式
      // 表格数据
      tableData: [],
      pageTotal: 0, // 数据数量
      loadingData: true, // 是否加载
      tableObject: { // 搜索传参数据
        page: 1,
        rows: 10,
        condition: {
          sysId: ''
        }
      },
      // 表头
      columns: [
        {
          title: '费用类型',
          dataIndex: 'costId',
          key: 'costId',
          align: 'center'
        },
        {
          title: '费用项ID',
          dataIndex: 'costInfoId',
          key: 'costInfoId',
          align: 'center',
          ellipsis: true
        },
        {
          title: '费用项名称',
          dataIndex: 'costInfoName',
          key: 'costInfoName',
          align: 'center',
          ellipsis: false
        },
        {
          title: '支付状态',
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          ellipsis: false
        },
        {
          title: '实收金额',
          dataIndex: 'paidMoney',
          key: 'paidMoney',
          align: 'center',
          ellipsis: false
        },
        {
          title: '实收日期',
          dataIndex: 'paidDate',
          key: 'paidDate',
          align: 'center',
          ellipsis: false
        },
        {
          title: '代理费',
          dataIndex: 'serveExp',
          key: 'serveExp',
          align: 'center',
          ellipsis: false
        },
        {
          title: '营销端收入',
          dataIndex: 'saleSalary',
          key: 'saleSalary',
          align: 'center',
          ellipsis: false
        },
        {
          title: '流程收入',
          dataIndex: 'flowSalary',
          key: 'flowSalary',
          align: 'center',
          ellipsis: false
        },
        {
          title: '业务线收入',
          dataIndex: 'busSalary',
          key: 'busSalary',
          align: 'center',
          ellipsis: false
        },
        {
          title: '集团预留',
          dataIndex: 'blocSalary',
          key: 'blocSalary',
          align: 'center',
          ellipsis: false
        },
        {
          title: '组ID',
          dataIndex: 'gwGroupId',
          key: 'gwGroupId',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问姓名',
          dataIndex: 'gwRealname',
          key: 'gwRealname',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问昵称',
          dataIndex: 'gwNickname',
          key: 'gwNickname',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问组别',
          dataIndex: 'gwGroupName',
          key: 'gwGroupName',
          align: 'center',
          ellipsis: false
        },
        {
          title: '顾问区域/分公司',
          dataIndex: 'gwUpperGrpName',
          key: 'gwUpperGrpName',
          align: 'center',
          ellipsis: false
        }
      ],
      companyArr: [], // 分公司列表数据
      groupArr: [], // 分组列表数据
      provinceData: [], // 省数据
      counselorArr: [], // 顾问列表数据
      companyName: '', // 分公司名称
      groupNameId: '' // 分组id
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    // 获取当前月第一天
    getCurrentMonthFirst() {
      const date = new Date()
      date.setDate(1)
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    },
    // 获取当前日期
    getNowFormatDate() {
      const date = new Date()
      const seperator1 = '-'
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let strDate = date.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = '0' + strDate
      }
      const currentdate = year + seperator1 + month + seperator1 + strDate
      return currentdate
    }
  },
  created() {
    this.tableObject.condition.sysId = this.userInfo.id
    this.tableObject.condition.tenantId = this.userInfo.tenantId
    this.tableObject.condition.paytimeS = this.getCurrentMonthFirst
    this.tableObject.condition.paytimeE = this.getNowFormatDate
    if (this.tableObject.condition.sysId) {
      // 进入页面默认调一次表格接口
      this.getOrderDatas(this.tableObject)
    }
  },
  methods: {
    moment,
    // 表格数据
    getOrderDatas(val, type) {
      this.loadingData = true
      getCostPageList(val).then(res => {
        if (res) {
          this.tableData = res.data.page.list
          this.pageTotal = res.data.page.total
          // 如果是查询数据
          if (type === '1') {
            this.$message.success('查询成功')
          } else if (type === '2') { // 重置时
            this.$message.info('表格数据已初始化')
          }
          // 表格数据转换
          if (this.tableData) {
            this.tableData.map(i => {
              // 费用类型
              if (i.costId === 1) {
                i.costId = '官费'
              } else if (i.costId === 2) {
                i.costId = '代理费'
              } else if (i.costId === 3) {
                i.costId = '第三方费用'
              }
              // 支付状态
              if (i.status === 1) {
                i.status = '已支付'
              } else if (i.status === -1) {
                i.status = '退款'
              } else if (i.status === -2) {
                i.status = '退款'
              }
            })
          }
        }
      }).then(() => {
        this.loadingData = false
      }).catch(err => {
        console.log(err, '表格数据')
      })
    },
    // 创建时间change事件
    createChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.addtimeS = dateString[0]
        this.tableObject.condition.addtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.addtimeS
        delete this.tableObject.condition.addtimeE
      }
    },
    // 支付时间change事件
    payChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.paytimeS = dateString[0]
        this.tableObject.condition.paytimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        // this.tableObject.condition.paytimeS = this.getCurrentMonthFirst
        // this.tableObject.condition.paytimeE = this.getNowFormatDate
        delete this.tableObject.condition.paytimeS
        delete this.tableObject.condition.paytimeE
      }
    },
    // 不可选择的日期控制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 费用类型change事件
    orderChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.costId = val
      } else {
        delete this.tableObject.condition.costId
      }
    },
    // 请款单号change事件
    orderCodeChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.requestFundsCode = val.target.value
      } else {
        delete this.tableObject.condition.requestFundsCode
      }
    },
    // 费用名称change事件
    payCodeChange(val) {
      if (val.target.value !== '') {
        this.tableObject.condition.costInfoName = val.target.value
      } else {
        delete this.tableObject.condition.costInfoName
      }
    },
    // 注册时间change事件
    registerChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.regtimeS = dateString[0]
        this.tableObject.condition.regtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.regtimeS
        delete this.tableObject.condition.regtimeE
      }
    },
    // 首次成交日期change事件
    firstChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.firstPaytimeS = dateString[0]
        this.tableObject.condition.firstPaytimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.firstPaytimeS
        delete this.tableObject.condition.firstPaytimeE
      }
    },
    // 顾问区域分公司change事件
    filialeChange(val) {
      if (val !== undefined) {
        this.companyName = val // 获取公司名称参数
        this.tableObject.condition.company = val
        // 获取分组列表
        const groups = {
          sysId: this.userInfo.id,
          company: this.companyName
        }
        getGroup(groups).then(res => {
          if (res) {
            this.groupArr = res.data.groupList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.groupArr = []
        this.counselorArr = []
        this.insetData.groupeType = undefined
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.company
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问区域分公司获得焦点事件
    filialeFocus() {
      // 获取分公司列表
      const datas = {
        sysId: this.userInfo.id
      }
      getCompany(datas).then(res => {
        if (res) {
          this.companyArr = res.data.companyList
        }
      }).catch((err) => {
        console.log(err, '分公司')
      })
    },
    // 顾问组别change事件
    groupeChange(val) {
      if (val !== undefined) {
        this.groupNameId = val
        this.tableObject.condition.groupId = val
        // 获取顾问列表
        const counselors = {
          sysId: this.userInfo.id,
          groupId: this.groupNameId
        }
        getCounselor(counselors).then(res => {
          if (res) {
            this.counselorArr = res.data.gwList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.counselorArr = []
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问姓名change事件
    counselorChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.sysPramId = val // 赋值顾问id
      } else {
        delete this.tableObject.condition.sysPramId
      }
    },
    // 是否退款change事件
    refundChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.status = val
      } else {
        delete this.tableObject.condition.status
      }
    },
    // 退款时间change事件
    refundTimeChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.refundtimeS = dateString[0]
        this.tableObject.condition.refundtimeE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.refundtimeS
        delete this.tableObject.condition.refundtimeE
      }
    },
    // 请款时间change事件
    requestFundsDateChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.requestFundsDateS = dateString[0]
        this.tableObject.condition.requestFundsDateE = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.requestFundsDateS
        delete this.tableObject.condition.requestFundsDateE
      }
    },
    // 查询按钮
    inquireClick(val) {
      if (val === '1') {
        this.getOrderDatas(this.tableObject, '1')
      }
    },
    // 导出按钮
    exportClick(val) {
      if (val === '2') {
        const fileDownData = { ...this.tableObject }
        exportCostBaseData(fileDownData).then(res => {
          if (res.code) {
            this.$message.error(res.message)
          } else {
            const now = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            // console.log(now)
            fileDownload(res.data, '费用明细' + now + '.xls')
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    // 重置按钮
    insetClick(val) {
      if (val === '3') {
        this.tableObject = {
          page: 1,
          rows: 10,
          condition: {
            sysId: this.userInfo.id,
            paytimeS: this.getCurrentMonthFirst,
            paytimeE: this.getNowFormatDate
          }
        }
        this.getOrderDatas(this.tableObject, '2')
        // 重置搜索框
        this.insetData = {
          createTime: undefined, // 创建时间
          payTime: undefined, // 支付时间
          costId: undefined, // 订单渠道
          requestFundsCode: undefined, // 请款单号
          costInfoName: undefined, // 费用名称
          registerTime: undefined, // 注册时间
          successTime: undefined, // 首次成交日期
          filialeType: undefined, // 顾问区域分公司
          groupeType: undefined, // 顾问组别
          counselorType: undefined, // 顾问姓名
          refundTime: undefined, // 退款时间
          requestFundsDate: undefined // 请款时间
        }
        this.provinceData = [] // 省数组清空
        this.groupArr = [] // 组别数组清空
        this.counselorArr = [] // 顾问姓名数组清空
      }
    },
    // 分页change
    pageChange(page, pageSize) {
      if (page && pageSize) {
        this.tableObject.page = page
        this.tableObject.rows = pageSize
        this.getOrderDatas(this.tableObject)
      }
    },
    // 显示数据量change
    pageSizePage(current, size) {
      if (current && size) {
        this.tableObject.page = current
        this.tableObject.rows = size
        this.getOrderDatas(this.tableObject)
      }
    }
  }
};
</script>
<style scoped lang="scss">
.pageClass {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  height: 80px;
}
.pageClass>.ant-pagination {
  position: absolute;
  right: 0;
  top: 10px;
}
</style>
